import { ChainId, Currency, Token } from '@pancakeswap/sdk'
import { Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import styled from 'styled-components'
// import useNativeCurrency from 'hooks/useNativeCurrency'
// import { useTranslation } from '@pancakeswap/localization'

import { SUGGESTED_BASES } from 'config/constants/exchange'
import { AutoColumn } from '../Layout/Column'
// import QuestionHelper from '../QuestionHelper'
// import { AutoRow } from '../Layout/Row'
import { CurrencyLogo } from '../Logo'
// import { CommonBasesType } from './types'

const ButtonWrapper = styled.div<{ $isMobile: boolean }>`
  display: inline-block;
  vertical-align: top;
  margin-right: ${({ $isMobile }) => ($isMobile ? '10px' : '22px')};
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
`

const BaseWrapper = styled.div<{ disable?: boolean }>`
  // border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.dropdown)};
  // border-radius: 10px;
  display: flex;
  // padding: 10px;
  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    opacity: 0.6;
    // background-color: ${({ theme, disable }) => !disable && theme.colors.background};
  }
  // background-color: ${({ theme, disable }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: // commonBasesType,
{
  chainId?: ChainId
  // commonBasesType
  selectedCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  // const native = useNativeCurrency()
  // const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  // const pinTokenDescText = commonBasesType === CommonBasesType.SWAP_LIMITORDER ? t('Common tokens') : t('Common bases')
  return (
    <AutoColumn gap="md">
      {/* <AutoRow>
        <Text fontSize="14px">{pinTokenDescText}</Text>
        {commonBasesType === CommonBasesType.LIQUIDITY && (
          <QuestionHelper text={t('These tokens are commonly paired with other tokens.')} ml="4px" />
        )}
      </AutoRow> */}
      <RowWrapper>
        {/* <ButtonWrapper>
          <BaseWrapper
            onClick={() => {
              if (!selectedCurrency || !selectedCurrency.isNative) {
                onSelect(native)
              }
            }}
            disable={selectedCurrency?.isNative}
          >
            <CurrencyLogo currency={native} style={{ marginRight: 8 }} />
            <Text>{native?.symbol}</Text>
          </BaseWrapper>
        </ButtonWrapper> */}
        {(chainId ? SUGGESTED_BASES[chainId] || [] : []).map((token: Token) => {
          const selected = selectedCurrency?.equals(token)
          return (
            <ButtonWrapper
              className="coin-btn2"
              $isMobile={isMobile}
              style={isMobile ? { padding: '10px 12px' } : { padding: '10px 18px' }}
              key={token.address}
            >
              <BaseWrapper onClick={() => !selected && onSelect(token)} disable={selected}>
                <CurrencyLogo
                  size={isMobile ? '24px' : '36px'}
                  currency={token}
                  style={{ marginRight: 8, borderRadius: '50%' }}
                />
                <Text>{token.symbol}</Text>
              </BaseWrapper>
            </ButtonWrapper>
          )
        })}
      </RowWrapper>
    </AutoColumn>
  )
}
